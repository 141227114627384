import { lazy } from 'react';
import { RoleBasedGuard } from 'src/auth/guard';

// ----------------------------------------------------------------------

const ProfileReviewListPage = lazy(() => import('src/pages/profile-review/list'));
const ProfileReviewAdminEditPage = lazy(() => import('src/pages/profile-review/edit'));
const ProfileReviewStartPage = lazy(() => import('src/pages/profile-review/start'));
const ProfileReviewFormPage = lazy(() => import('src/pages/profile-review/form'));
const ProfileReviewConfirmationPage = lazy(() => import('src/pages/profile-review/confirmation'));

// ----------------------------------------------------------------------

const guardedProfileReviewListPage = (
  <RoleBasedGuard navigateTo roles={['User.Admin']}>
    <ProfileReviewListPage />
  </RoleBasedGuard>
);

const guardedProfileReviewAdminEditPage = (
  <RoleBasedGuard navigateTo roles={['User.Admin']}>
    <ProfileReviewAdminEditPage />
  </RoleBasedGuard>
);

const guardedProfileReviewFormPage = (
  <RoleBasedGuard navigateTo roles={['User.Admin', 'User.User']}>
    <ProfileReviewFormPage />
  </RoleBasedGuard>
);

const guardedProfileReviewStartPage = (
  <RoleBasedGuard navigateTo roles={['User.User', 'User.Admin']}>
    <ProfileReviewStartPage />
  </RoleBasedGuard>
);

const guardedProfileReviewConfirmation = (
  <RoleBasedGuard navigateTo roles={['User.User', 'User.Admin']}>
    <ProfileReviewConfirmationPage />
  </RoleBasedGuard>
);

export const profileReviewRoutes = [
  {
    path: 'profile-review',
    children: [
      { element: guardedProfileReviewListPage, index: true },
      {
        path: ':profileReviewId',
        children: [
          { element: guardedProfileReviewAdminEditPage, index: true },
          { path: 'start', element: guardedProfileReviewStartPage },
          { path: 'form', element: guardedProfileReviewFormPage },
          { path: 'confirmation', element: guardedProfileReviewConfirmation },
        ],
      },
    ],
  },
];
