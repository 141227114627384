import { lazy } from 'react';
import { RoleBasedGuard } from 'src/auth/guard';

// ----------------------------------------------------------------------

const WorkOrderListPage = lazy(() => import('src/pages/workorder/list'));
const WorkOrderCreatePage = lazy(() => import('src/pages/workorder/new'));
const WorkOrderEditPage = lazy(() => import('src/pages/workorder/edit'));

// ----------------------------------------------------------------------

const guardedWorkOrderListPage = (
  <RoleBasedGuard navigateTo roles={['fleet.mechanic']}>
    <WorkOrderListPage />
  </RoleBasedGuard>
);

const guardedWorkOrderCreatePage = (
  <RoleBasedGuard navigateTo roles={['fleet.mechanic']}>
    <WorkOrderCreatePage />
  </RoleBasedGuard>
);

const guardedWorkOrderEditPage = (
  <RoleBasedGuard navigateTo roles={['fleet.mechanic']}>
    <WorkOrderEditPage />
  </RoleBasedGuard>
);

export const workOrderRoutes = [
  {
    path: 'workorder',
    children: [
      { element: guardedWorkOrderListPage, index: true },
      { path: 'new', element: guardedWorkOrderCreatePage },
      { path: ':id/edit', element: guardedWorkOrderEditPage },
    ],
  },
];
