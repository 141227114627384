import { lazy } from 'react';
import { RoleBasedGuard } from 'src/auth/guard';

// ----------------------------------------------------------------------

const VehicleListPage = lazy(() => import('src/pages/vehicle/list'));
const VehicleCreatePage = lazy(() => import('src/pages/vehicle/new'));
const VehicleEditPage = lazy(() => import('src/pages/vehicle/edit'));
const VehicleDetailsPage = lazy(() => import('src/pages/vehicle/details'));

// ----------------------------------------------------------------------

const guardedVehicleListPage = (
  <RoleBasedGuard navigateTo roles={['fleet.mechanic']}>
    <VehicleListPage />
  </RoleBasedGuard>
);

const guardedVehicleCreatePage = (
  <RoleBasedGuard navigateTo roles={['fleet.mechanic']}>
    <VehicleCreatePage />
  </RoleBasedGuard>
);

const guardedVehicleEditPage = (
  <RoleBasedGuard navigateTo roles={['fleet.mechanic']}>
    <VehicleEditPage />
  </RoleBasedGuard>
);

const guardedVehicleDetailsPage = (
  <RoleBasedGuard navigateTo roles={['fleet.mechanic']}>
    <VehicleDetailsPage />
  </RoleBasedGuard>
);

export const vehicleRoutes = [
  {
    path: 'vehicle',
    children: [
      { element: guardedVehicleListPage, index: true },
      { path: 'new', element: guardedVehicleCreatePage },
      { path: ':id/edit', element: guardedVehicleEditPage },
      { path: ':id', element: guardedVehicleDetailsPage },
    ],
  },
];
