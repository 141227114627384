import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const API_ENDPOINTS = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  student: {
    list: '/api/student/list',
    details: (asn) => `/api/student/${asn}`,
    profileReview: (id) => `/api/studentprofilereview/${id}`,
  },
  profileReview: {
    init: '/api/studentprofilereview',
    print: (id) => `/api/studentprofilereview/${id}/print`,
    export: '/api/studentprofilereview/export',
  },
  address: {
    query: '/api/addresslookup',
  },
  vehicle: '/api/vehicle',
  vehicleType: '/api/vehicle/type',
  component: '/api/vehicle/component',
  componentType: '/api/vehicle/component/type',
  part: '/api/part',
  workOrder: '/api/workorder',
  purchaseOrder: '/api/purchaseorder',
  routingRequest: '/api/busrouting/routingrequests',
  crossboundaryRequest: '/api/busrouting/crossboundary',
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};
