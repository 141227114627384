import { m } from 'framer-motion';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// @mui
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// hooks
import { useAuthContext } from 'src/auth/hooks/use-auth-context';
import { useMockedUser } from 'src/hooks/use-mocked-user';
// assets
import { ForbiddenIllustration } from 'src/assets/illustrations';
// components
import { MotionContainer, varBounce } from 'src/components/animate';
// ----------------------------------------------------------------------

export default function RoleBasedGuard({ hasContent, navigateTo, roles, children, sx }) {
  // Logic here to get current user role
  const { user } = useMockedUser();
  const auth = useAuthContext();

  // const currentRole = ['User.Admin','User.User'];
  const userRoles = auth?.user?.roles;

  if (typeof roles !== 'undefined' && !userRoles?.some((role) => roles.includes(role)) && !userRoles?.includes('super.admin')) {
    return navigateTo ? (
      <Navigate to="/403" replace />
    ) : (
      (hasContent && (
        <Container component={MotionContainer} sx={{ textAlign: 'center', ...sx }}>
          <m.div variants={varBounce().in}>
            <Typography variant="h3" paragraph>
              Permission Denied
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <Typography sx={{ color: 'text.secondary' }}>
              You do not have permission to access this page
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <ForbiddenIllustration
              sx={{
                height: 260,
                my: { xs: 5, sm: 10 },
              }}
            />
          </m.div>
        </Container>
      )) ||
        null
    );
  }

  return <>{children}</>;
}

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  navigateTo: PropTypes.bool,
  hasContent: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string),
  sx: PropTypes.object,
};
