import { lazy } from 'react';
import { RoleBasedGuard } from 'src/auth/guard';

// ----------------------------------------------------------------------

const RoutingRequestListPage = lazy(() => import('src/pages/routing-request/list'));
const RoutingRequestDetailsPage = lazy(() => import('src/pages/routing-request/details'));


// ----------------------------------------------------------------------

const guardedRoutingRequestListPage = (
  <RoleBasedGuard navigateTo roles={['transporation.manager', 'transportation.admin']}>
    <RoutingRequestListPage />
  </RoleBasedGuard>
);

const guardedRoutingRequestDetailsPage = (
  <RoleBasedGuard navigateTo roles={['transporation.manager', 'transportation.admin']}>
    <RoutingRequestDetailsPage />
  </RoleBasedGuard>
);

export const routingRequestRoutes = [
  {
    path: 'routing-request',
    children: [
      { element: guardedRoutingRequestListPage, index: true },
      { path: ':id', element: guardedRoutingRequestDetailsPage },
    ],
  },
];
