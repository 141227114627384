import React from 'react';
import PropTypes from 'prop-types';
import Page500 from 'src/pages/500';
import ThemeProvider from 'src/theme';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';



export class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
      this.resetError = this.resetError.bind(this);
    }
  
    static getDerivedStateFromError(error) {
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      console.log(error, errorInfo);
    }
  
    resetError() {
      this.setState({ hasError: false });
    }
  
    render() {
      const { hasError } = this.state;
      const { children } = this.props;
  
      if (hasError) {
        return (
          <SettingsProvider>
            <ThemeProvider>
              <SettingsDrawer />
              <Page500 resetError={this.resetError} />
            </ThemeProvider>
          </SettingsProvider>
        );
      }
  
      return children;
    }
  }
  
  ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
  };