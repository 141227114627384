import { lazy } from 'react';
import { RoleBasedGuard } from 'src/auth/guard';

// ----------------------------------------------------------------------

const CrossBoundaryRequestView = lazy(() => import('src/pages/crossboundary-request/list'));
const CrossboundaryRequestDetailsPage = lazy(() => import('src/pages/crossboundary-request/details'));


// ----------------------------------------------------------------------

const guardedCrossboundaryRequestListPage = (
  <RoleBasedGuard navigateTo roles={['transporation.manager', 'transportation.admin']}>
    <CrossBoundaryRequestView />
  </RoleBasedGuard>
);

const guardedCrossboundaryRequestDetailsPage = (
  <RoleBasedGuard navigateTo roles={['transporation.manager', 'transportation.admin']}>
    <CrossboundaryRequestDetailsPage />
  </RoleBasedGuard>
);

export const crossboundaryRequestRoutes = [
  {
    path: 'crossboundary-request',
    children: [
      { element: guardedCrossboundaryRequestListPage, index: true },
      { path: ':id', element: guardedCrossboundaryRequestDetailsPage },
    ],
  },
];
