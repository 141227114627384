import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
// routes
import { PATH_AFTER_LOGIN } from 'src/config-global';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';
//
import { mutate } from 'swr';
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  admin: null,
  loading: true,
  students: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
      admin: action.payload.admin,
      students: action.payload.students,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
      admin: action.payload.admin,
      students: action.payload.students,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
      admin: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      // const accessToken = sessionStorage.getItem(STORAGE_KEY);
      const accessToken = localStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.get(API_ENDPOINTS.auth.me);

        const { user, students } = response.data;
        // get current route to see if they are on the login screen
        // if they are, redirect them to the dashboard
        // eslint-disable-next-line no-restricted-globals
        const currentRoute = location.pathname;
        if (currentRoute === '/login') navigate(PATH_AFTER_LOGIN, { replace: true });

        dispatch({
          type: 'INITIAL',
          payload: {
            user: user.user,
            admin: user.admin,
            students: user.students,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
            admin: null,
            students: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
          admin: null,
          students: null,
        },
      });
    }
  }, [navigate]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    const data = {
      email,
      password,
    };

    const response = await axios.post(API_ENDPOINTS.auth.login, data);

    const { accessToken, user } = response.data;

    setSession(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  }, []);

  // Social LOGIN
  const socialLogin = useCallback(async (provider, data) => {
    const response = await axios.post(`${API_ENDPOINTS.auth.login}/${provider}`, data);

    const { accessToken, claims } = response.data;
    setSession(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user: claims.user,
        admin: claims.admin,
        students: claims.students,
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const data = {
      email,
      password,
      firstName,
      lastName,
    };

    const response = await axios.post(API_ENDPOINTS.auth.register, data);

    const { accessToken, user } = response.data;

    sessionStorage.setItem(STORAGE_KEY, accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    // clear all useSWR cache
    // mutate('/api/auth/me', null, false);
    mutate('/api/student/list', [], false);
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      admin: state.admin,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      socialLogin,
      register,
      logout,
    }),
    [login, socialLogin, logout, register, state.user, state.admin, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
