import { lazy } from 'react';
import { RoleBasedGuard } from 'src/auth/guard';

// ----------------------------------------------------------------------

const PurchaseOrderListPage = lazy(() => import('src/pages/purchase-order/list'));
const PurchaseOrderCreatePage = lazy(() => import('src/pages/purchase-order/new'));
const PurchaseOrderEditPage = lazy(() => import('src/pages/purchase-order/edit'));

// ----------------------------------------------------------------------

const guardedPurchaseOrderListPage = (
  <RoleBasedGuard navigateTo roles={['fleet.mechanic', 'fleet.manager']}>
    <PurchaseOrderListPage />
  </RoleBasedGuard>
);

const guardedPurchaseOrderCreatePage = (
  <RoleBasedGuard navigateTo roles={['fleet.mechanic', 'fleet.manager']}>
    <PurchaseOrderCreatePage />
  </RoleBasedGuard>
);

const guardedPurchaseOrderEditPage = (
  <RoleBasedGuard navigateTo roles={['fleet.mechanic', 'fleet.manager']}>
    <PurchaseOrderEditPage />
  </RoleBasedGuard>
);

export const purchaseOrderRoutes = [
  {
    path: 'purchase-order',
    children: [
      { element: guardedPurchaseOrderListPage, index: true },
      { path: 'new', element: guardedPurchaseOrderCreatePage },
      { path: ':id/edit', element: guardedPurchaseOrderEditPage },
    ],
  },
];
