import { lazy } from 'react';
import { RoleBasedGuard } from 'src/auth/guard';

// ----------------------------------------------------------------------

const StudentListPage = lazy(() => import('src/pages/student/list'));
const StudentProfilePage = lazy(() => import('src/pages/student/profile'));

// ----------------------------------------------------------------------

const guardedStudentListPage = (
  <RoleBasedGuard navigateTo roles={['User.Admin']}>
    <StudentListPage />
  </RoleBasedGuard>
);

export const studentRoutes = [
  {
    path: 'student',
    children: [
      { element: guardedStudentListPage, index: true },
      { path: ':asn', element: <StudentProfilePage /> },
    ],
  },
];
