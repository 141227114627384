// ----------------------------------------------------------------------

export default function FormControlLabel(theme) {
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: theme.spacing(1),
        },
      },
    },
  };
}
