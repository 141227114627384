import { useMemo, useState } from 'react';
import useSWR from 'swr';

import axiosInstance, { API_ENDPOINTS, fetcher } from 'src/utils/axios';

// ----------------------------------------------------------------------

export function useGetStudents() {
  const URL = API_ENDPOINTS.student.list;

  const [lastValidated, setLastValidated] = useState(null);
  
  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, {
    onSuccess: () => {
      console.log('Students fetched successfully');
      setLastValidated(new Date());
    },
  });

  const memoizedValue = useMemo(
    () => ({
      students: data,
      studentsLoading: isLoading,
      studentsError: error,
      studentsValidating: isValidating,
      studentsEmpty: !isLoading && !data.length,
      lastValidated,
      revalidateStudents: mutate,
    }),
    [data, error, isLoading, isValidating, lastValidated, mutate]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetStudent(studentId, expand) {
  let URL = `${API_ENDPOINTS.student.details(studentId)}`;

  if(expand) {
    URL = `${URL}?expand=${expand.join(',')}`;
  }
  
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      student: data,
      studentLoading: isLoading,
      studentError: error,
      studentValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useUpdateStudentAddress(studentId) {
  const URL = `${API_ENDPOINTS.student.details(studentId)}/address`;

  const updateStudentAddress = async (address) => {
    const response = await axiosInstance.put(URL, 
      {...address}
    );

    return response;
  };

  return updateStudentAddress;
}
